import callApi from "./apiCaller";

const crud = {
  list: (modelName, inputData, callback) => {
    callApi(`${modelName}/list`, "post", inputData).then((res) => {
      return callback(res);
    });
  },

  create: (modelName, inputData, callback) => {
    callApi(`${modelName}/new`, "post", inputData).then((res) => {
      return callback(res);
    });
  },
  get: (modelName, id, callback) => {
    callApi(`${modelName}/${id}`, "get").then((res) => {
      return callback(res);
    });
  },
  update: (modelName, id, updateData, callback) => {
    callApi(`${modelName}/${id}/update`, "post", updateData).then((res) => {
      return callback(res);
    });
  },
  remove: (modelName, id, callback) => {
    let jsonBody = {};
    callApi(`${modelName}/${id}/remove`, "post", jsonBody).then((res) => {
      return callback(res);
    });
  },
  signup: (modelName, inputData, callback) => {
    let jsonBody = {};
    jsonBody[`${modelName}`] = inputData;
    callApi(`${modelName}/signup`, "post", jsonBody).then((res) => {
      return callback(res);
    });
  },
};

export default crud;
