import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import crud from "./util/crud";
import Loadable from "react-loadable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-modal-slider/lib/main.css";
import Notifications, { notify } from "react-notify-toast";
import async from "async";
import _ from "lodash";
import LoadingComponent from "./components/loader";
import Header from "./components/header/index";
import Sidebar from "./components/SideBar";
import Connector from "./components/Connector";
// import Footer from './components/Footer';
// import NotFound from './components/NotFound';
// import Sidebar from './components/Sidebar/Sidebar';
// import Login from './components/Login/Login';
import callApi from "./util/apiCaller";
// import sidebarLinks from './constants/sidebar';
// import BreadCrumb from "./components/BreadCrumb";
import Error404 from "./components/error/error-404";

// import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";
import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/css/bootstrap-datetimepicker.min.css";
import "./assets/css/animate.min.css";
import "./assets/plugins/jvectormap/jquery-jvectormap-2.0.3.css";
import "./assets/css/admin.css";

const PROTOCOL = window.location.protocol;
const HOST = window.location.host;
const endpoint = `${process.env.REACT_APP_API_URL}`;

const AsyncLoginPage = Loadable({
  loader: () => import("./modules/App/LoginPage/LoginPage"),
  loading: LoadingComponent,
});
const AsyncProfile = Loadable({
  loader: () => import("./modules/Profile/Profile/Profile"),
  loading: LoadingComponent,
});
const AsyncLiveList = Loadable({
  loader: () => import("./modules/Content/Live/Live"),
  loading: LoadingComponent,
});
const AsyncDashboard = Loadable({
  loader: () => import("./modules/App/Dashboard/Dashboard"),
  loading: LoadingComponent,
});
const AsyncUserList = Loadable({
  loader: () => import("./modules/Content/Users/UserList"),
  loading: LoadingComponent,
});
const AsyncBotNotificationList = Loadable({
  loader: () => import("./modules/Content/Bot/BotNotificationList"),
  loading: LoadingComponent,
});

const AsyncAdminList = Loadable({
  loader: () => import("./modules/Content/Admins/Admins"),
  loading: LoadingComponent,
});

const AsyncFeedList = Loadable({
  loader: () => import("./modules/Content/Feed/FeedList"),
  loading: LoadingComponent,
});
const AsyncQuestionList = Loadable({
  loader: () => import("./modules/Content/Question/QuestionList"),
  loading: LoadingComponent,
});
const AsyncBanList = Loadable({
  loader: () => import("./modules/Content/Ban/BanList"),
  loading: LoadingComponent,
});
const AsyncTodayMatchList = Loadable({
  loader: () => import("./modules/Content/TodayMatches/TodayMatchesList"),
  loading: LoadingComponent,
});
const AsyncTapeList = Loadable({
  loader: () => import("./modules/Content/Tape/TapeList"),
  loading: LoadingComponent,
});
const AsyncTagsList = Loadable({
  loader: () => import("./modules/Content/Tags/TagsList"),
  loading: LoadingComponent,
});

const AsyncCompetitionList = Loadable({
  loader: () => import("./modules/Content/Competition/CompetitionList"),
  loading: LoadingComponent,
});

const AsyncInviteList = Loadable({
  loader: () => import("./modules/Content/Invites/InvitesList"),
  loading: LoadingComponent,
});
const AsyncClubList = Loadable({
  loader: () => import("./modules/Content/Club/ClubList"),
  loading: LoadingComponent,
});
const AsyncFanClubList = Loadable({
  loader: () => import("./modules/Content/FanClub/FanClubList"),
  loading: LoadingComponent,
});
const AsyncReportContentList = Loadable({
  loader: () => import("./modules/Content/ReportContent/ReportContentList"),
  loading: LoadingComponent,
});
const AsyncSport = Loadable({
  loader: () => import("./modules/Content/Sports/SportsList.js"),
  loading: LoadingComponent,
});
const AsyncPlayer = Loadable({
  loader: () => import("./modules/Content/Player/PlayerList.js"),
  loading: LoadingComponent,
});
const AsyncMatches = Loadable({
  loader: () => import("./modules/Content/Matches/MatchesList.js"),
  loading: LoadingComponent,
});
const AsyncMatchCommentaries = Loadable({
  loader: () =>
    import("./modules/Content/MatchCommentaries/MatchCommentariesList.js"),
  loading: LoadingComponent,
});
const PrivateRoute = ({
  component: Component,
  isAuthenticated: isAuthenticated,
  userPermissions,
  shouldHavePermissions,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { _refurl: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor() {
    super();
    this.state = {
      isMounted: false,
      isLoading: true,
      userId: "",
      roles: [],
      user: {},
      isAuthenticated: false,
      token: "",
      isLoggingIn: false,
      links: [],
      userprefId: "",
      breadcrumbs: [
        {
          link: "/profile",
          name: "Home",
          isActive: false,
        },
      ],
    };
    this.isAuthenticated = false;
  }

  componentDidMount() {
    let token = window.localStorage.getItem("token");
    let userId = window.localStorage.getItem("userId");
    if (token && token !== "") {
      this.setState({
        isMounted: true,
        isLoading: false,
        isAuthenticated: true,
        userId: userId,
        token: token,
      });
      this.fetchItem();
    }
  }

  fetchItem = () => {
    let id = window.localStorage.getItem("userId");
    crud.get("admin", id, (res) => {
      if (res.status === "Success") {
        this.setState({
          userId: res.data.admin._id,
          roles: res.data.admin.roles,
        });
      } else {
      }
    });
  };

  updateAgent = (agent) => {
    callApi(`v2/agents/${this.state.agentid}/update`, "POST", {
      agent: agent,
    }).then((res) => {
      if (res.status === "Success") {
        this.setState({
          agent: res.data.agent,
        });
        this.showMessage("Updated", "success");
      }
    });
  };

  fetchAgent = (id, next) => {
    callApi(`profile/user/${id}/info`, "GET").then((res) => {
      return next(null, res.data.user);
    });
  };

  loginUser = (creds) => {
    this.setState({
      isLoggingIn: true,
    });
    callApi(`admin/login`, "post", {
      admin: creds,
    }).then((res) => {
      this.setState({
        isLoggingIn: false,
      });
      if (res.status === "Success") {
        if (res.data.admin) {
          window.localStorage.setItem("token", res?.data?.token);
          window.localStorage.setItem("userId", res?.data?.admin?._id);
          window.localStorage.setItem("role", res?.data?.admin?.role);
          window.location = "/";
        }
      } else {
        this.showMessage(res.data.message, "error");
      }
    });
  };

  verifyToken = (token, next) => {
    const userId = window.localStorage.getItem("userId");
    if (userId) {
      callApi(`profile/user/${userId}/info`, "get").then((res) => {
        if (res.status === "Success") {
          let user = res.data.user;
          return next(null, user);
        } else {
          return next("Unauthorized");
        }
      });
    }
  };

  logoutUser = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("role");
    this.isAuthenticated = false;
    window.location = "/";
  };

  showMessage = (msg, msgtype) => {
    notify.show(msg, msgtype, 3000);
  };

  notifyToAgent = (msg) => {
    let text = msg.text;
    let options = {};
    if (msg.isTimedNotification) {
      options.autoClose = msg.timer;
    } else {
      options.autoClose = false;
    }
    if (msg.notification_type === "success") {
      toast.success(text, options);
    } else {
      toast.info(text, options);
    }
  };

  clearAgentNotifications = () => {
    toast.dismiss();
  };

  notifyToAdmin = (message, link, timeout) => {
    let notification = this.state.notification;
    notification.message = message;
    notification.link = link ? link : "";
    notification.timeout = timeout;
    notification.msgId = this.generateMessageId(8);
    this.setState({
      notification: notification,
    });
  };

  rand = (min, max) => {
    let random = Math.random();
    return Math.floor(random * (max - min) + min);
  };

  generateMessageId = (length) => {
    let loopLength = length || 8;
    let allowsChars = "0123456789";
    let password = "";
    for (let index = 0; index < loopLength; ++index) {
      let charIndex = this.rand(0, allowsChars.length - 1);
      password += allowsChars[charIndex];
    }
    return password;
  };

  render() {
    console.log("App", this.state);
    return (
      <div>
        <Router>
          <div className="App">
            {/* <Connector user={this.state.user} /> */}
            <Route
              render={(props) => <Header {...props} />}
              isAuthenticated={this.state.isAuthenticated}
            />
            <Route
              render={(props) => (
                <Sidebar
                  {...props}
                  logOut={this.logoutUser}
                  roles={this.state.roles}
                  isAuthenticated={this.state.isAuthenticated}
                />
              )}
            />
            <div id="page-content-wrapper">
              <div id="wrapper">
                <div className="static-content-wrapper">
                  <div className="static-content">
                    <div className="page-content">
                      {/*<BreadCrumb*/}
                      {/*    breadcrumbs={this.state.breadcrumbs}*/}
                      {/*/>*/}
                      <div
                        className="container-fluid"
                        style={{ minHeight: "550px" }}
                      >
                        <Switch>
                          <Route
                            exact
                            path="/login"
                            render={(props) => (
                              <AsyncLoginPage
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/profile"
                            component={(props) => (
                              <AsyncProfile
                                {...props}
                                showMessage={this.showMessage}
                                user={this.state.user}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/live"
                            component={(props) => (
                              <AsyncLiveList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/users"
                            component={(props) => (
                              <AsyncUserList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/bot-notifications"
                            component={(props) => (
                              <AsyncBotNotificationList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/todayMatches"
                            component={(props) => (
                              <AsyncTodayMatchList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/feed"
                            component={(props) => (
                              <AsyncFeedList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/ban"
                            component={(props) => (
                              <AsyncBanList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/question"
                            component={(props) => (
                              <AsyncQuestionList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/tape"
                            component={(props) => (
                              <AsyncTapeList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/tags"
                            component={(props) => (
                              <AsyncTagsList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/competition"
                            component={(props) => (
                              <AsyncCompetitionList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/sport"
                            component={(props) => (
                              <AsyncSport
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/player"
                            component={(props) => (
                              <AsyncPlayer
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/matches"
                            component={(props) => (
                              <AsyncMatches
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/commentaries"
                            component={(props) => (
                              <AsyncMatchCommentaries
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/report"
                            component={(props) => (
                              <AsyncReportContentList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/fanclub"
                            component={(props) => (
                              <AsyncFanClubList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/club"
                            component={(props) => (
                              <AsyncClubList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          {/* <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/groupInfo"
                            component={(props) => (
                              <AsyncGroupInfoList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          /> */}
                          {/* <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/participantsInfo"
                            component={(props) => (
                              <AsyncParticipantsInfoList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          /> */}

                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/admins"
                            component={(props) => (
                              <AsyncAdminList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/invites"
                            component={(props) => (
                              <AsyncInviteList
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <PrivateRoute
                            isAuthenticated={this.state.isAuthenticated}
                            exact
                            path="/"
                            component={(props) => (
                              <AsyncDashboard
                                {...props}
                                isLoggingIn={this.state.isLoggingIn}
                                isAuthenticated={this.state.isAuthenticated}
                                user={this.state.user}
                                loginUser={this.loginUser}
                              />
                            )}
                          />
                          <Route
                            isAuthenticated={this.state.isAuthenticated}
                            component={Error404}
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
        <ToastContainer newestOnTop={true} />
        <Notifications options={{ zIndex: 10000 }} />
      </div>
    );
  }
}

export default App;
