import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Logo_icon } from "../imagepath";
import { MdOutlineSportsHandball } from "react-icons/md";

class SidebarNav extends Component {
  componentDidMount() {}

  render() {
    if (this.props.isAuthenticated) {
      const exclusionArray = [
        "login",
        "register",
        "forgot-password",
        "lock-screen",
        "error-404",
        "error-500",
      ];
      if (
        exclusionArray.indexOf(this.props.location.pathname.split("/")[1]) >= 0
      ) {
        return "";
      }
      const { location } = this.props;
      const pathname = location.pathname.split("/")[1];

      return (
        <>
          {/* Sidebar */}
          <div className="sidebar" id="sidebar">
            <div className="sidebar-logo">
              <Link to="/">
                <img src={Logo_icon} className="img-fluid" alt="" />
              </Link>
            </div>
            <div
              style={{ overflow: "auto" }}
              className="sidebar-inner slimscroll"
            >
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  <li
                    className={`${
                      pathname === "/" || pathname === "" ? "active" : ""
                    }`}
                  >
                    <Link to="/">
                      <i className="fas fa-columns" /> <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "live" ? "active" : ""}`}>
                    <Link to="/live">
                      <i className="fas fa-user" /> <span>Live</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "profile" ? "active" : ""}`}>
                    <Link to="/profile">
                      <i className="fas fa-user" /> <span>Profile</span>
                    </Link>
                  </li>

                  {this.props.roles.includes("EDITOR") && (
                    <li className={`${pathname === "admins" ? "active" : ""}`}>
                      <Link to="/admins">
                        <i className="fas fa-layer-group" />
                        <span>Admins</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("TODAYMATCHES")) && (
                    <li
                      className={`${
                        pathname === "todayMatches" ? "active" : ""
                      }`}
                    >
                      <Link to="/todayMatches">
                        <i className="fa fa-layer-group" />
                        <span> Today's Matches</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("TAPE")) && (
                    <li
                      className={`${
                        pathname === "tape" || pathname === "tape-detail"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/tape">
                        <i className="fa fa-newspaper" />
                        <span> Ticker Tape</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("TAGS")) && (
                    <li
                      className={`${
                        pathname === "tags" || pathname === "tags-detail"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/tags">
                        <i className="fa fa-newspaper" />
                        <span> Trending Tags</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("FEED")) && (
                    <li
                      className={`${
                        pathname === "feed" || pathname === "feed-detail"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/feed">
                        <i className="fa fa-newspaper" />
                        <span> Feed</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("QUESTION")) && (
                    <li
                      className={`${
                        pathname === "question" ||
                        pathname === "question-detail"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/question">
                        <i className="fa fa-layer-group" />
                        <span> Question</span>
                      </Link>
                    </li>
                  )}
                  <li
                    className={`${
                      pathname === "sport" || pathname === "feed-detail"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/sport">
                      <MdOutlineSportsHandball size="20px" />
                      <span>Sport</span>
                    </Link>
                  </li>
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("REPORT")) && (
                    <li className={`${pathname === "REPORT" ? "active" : ""}`}>
                      <Link to="/report">
                        <i className="fa fa-newspaper" />
                        <span> Reported Content</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("BAN")) && (
                    <li className={`${pathname === "ban" ? "active" : ""}`}>
                      <Link to="/ban">
                        <i className="fa fa-layer-group" />
                        <span> Ban Data</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("FANCLUB")) && (
                    <li className={`${pathname === "fanclub" ? "active" : ""}`}>
                      <Link to="/fanclub">
                        <i className="fa fa-users" />
                        <span> Fanclub</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("CLUB")) && (
                    <li className={`${pathname === "club" ? "active" : ""}`}>
                      <Link to="/club">
                        <i className="fa fa-users" />
                        <span> Club</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("BOT")) && (
                    <li
                      className={`${
                        pathname === "bot-notifications" ? "active" : ""
                      }`}
                    >
                      <Link to="/bot-notifications">
                        <i className="fa fa-users" />
                        <span> Bot Templates</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("PLAYER")) && (
                    <li className={`${pathname === "player" ? "active" : ""}`}>
                      <Link to="/player">
                        <i className="fa fa-users" />
                        <span> Player</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("COMPETITION")) && (
                    <li
                      className={`${
                        pathname === "competition" ? "active" : ""
                      }`}
                    >
                      <Link to="/competition">
                        <i className="fa fa-layer-group" />
                        <span> Competitions</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("MATCH")) && (
                    <li className={`${pathname === "matches" ? "active" : ""}`}>
                      <Link to="/matches">
                        <i className="fa fa-users" />
                        <span> Matches</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("COMMENTARIES")) && (
                    <li
                      className={`${
                        pathname === "commentaries" ? "active" : ""
                      }`}
                    >
                      <Link to="/commentaries">
                        <i className="fas fa-layer-group" />
                        <span> Match Commentaries</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("USERS")) && (
                    <li
                      className={`${
                        pathname === "users" ||
                        pathname === "add-category" ||
                        pathname === "edit-category"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/users">
                        <i className="fas fa-layer-group" />
                        <span>Users</span>
                      </Link>
                    </li>
                  )}
                  {(this.props.roles.includes("EDITOR") ||
                    this.props.roles.includes("INVITES")) && (
                    <li className={`${pathname === "invites" ? "active" : ""}`}>
                      <Link to="/invites">
                        <i className="fa fa-user-plus" />
                        <span>User Invites</span>
                      </Link>
                    </li>
                  )}
                  <li className={""}>
                    <a onClick={this.props.logOut}>
                      <i className="fas fa-lock" />
                      <span> Log Out</span>
                    </a>
                  </li>
                  <li className={""}>
                    <a onClick={this.props.logOut}>
                      <i className="fas fa-lock" />
                      <span> Log Out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Sidebar */}
        </>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(SidebarNav);
